
import React from 'react';
import { withStyles, Zoom} from '@material-ui/core';
import {connect} from "react-redux";
import {config} from "../../API/config";

const imgURL = config.imgURL;
class SearchExpCard extends React.Component {

    constructor(props){
        super(props);
      
        this.state = {
            foundImages: [false, false, false, false],
            visible: [true, false, false, false],
            timeStart: Date.now(),
            currentQIndex: 0,
            loaded: false,
            allSetUp: true
        }
      }

      async componentDidUpdate(prevProps){
        
        
        
        if(prevProps.slide && (prevProps.slide.id !== this.props.slide.id)){
            
            await this.setState({
                foundImages: [false, false, false, false],
                visible: [true, false, false, false],
                timeStart: Date.now(),
                currentQIndex: 0, 
                allSetUp: true
            })
        }
    }

    checkClick(e){
        e.preventDefault()

        if(this.state.currentQIndex === 4){
            return
        }

        const boundingBox = document.getElementById("mapImage").getBoundingClientRect()
        const x = e.clientX || e.center.x
        const y = e.clientY || e.center.y

        const relX = x - boundingBox.left
        const relY = y - boundingBox.top
        // //console.log(relX, relY, boundingBox)

        const nCols = boundingBox.width / this.props.slide.map.sizeX
        const nRows = boundingBox.height / this.props.slide.map.sizeY

        const gridX = Math.floor(relX / nCols)
        const gridY = Math.floor(relY / nRows)
        //console.log(gridX, gridY)
        //console.log(this.props.slide.queries[this.state.currentQIndex])

        //check if click was a hit

        const q_i_x = Number(this.props.slide.queries[this.state.currentQIndex].posX)
        const q_i_y = Number(this.props.slide.queries[this.state.currentQIndex].posY)
        let hit = gridX === q_i_x && gridY === q_i_y

        
        if(hit){

            const foundArray = this.state.foundImages
            foundArray[this.state.currentQIndex] = true

            const visArray = this.state.visible
            visArray[Math.min(this.state.currentQIndex + 1, 3)] = true

            this.setState({
                foundImages: foundArray,
                currentQIndex: this.state.currentQIndex + 1,
                visible: visArray
            })

            //console.log("hit", this.state.currentQIndex)
            if(this.state.currentQIndex === 3){
                // finished
                this.allFinished()
            }
    
        }

    }

    allFinished(){
        const duration = Date.now() - this.state.timeStart
        this.props.answerExperiment(duration)
        this.setState({allSetUp: false, loaded: false})
    }

    renderQueryImages(){

        let images = []

        const allFound = this.state.foundImages.reduce(function(pv, cv) { return Number(pv) + Number(cv); }, 0) === 4
        //console.log("sum", this.state.foundImages.reduce(function(pv, cv) { return Number(pv) + Number(cv); }, 0))
        for(let i = 0; i < this.props.slide.queries.length; i++){
                const isActive = this.state.foundImages[i]
                let className = isActive ? this.props.classes.imageActive : this.props.classes.image
                className = allFound ? this.props.classes.imageSolved : className
                // if(i === 0){

                //     images.push(
                //         <Zoom key={i} in={this.state.visible[i]}>
                    
                //             <img draggable="false" alt=""
                //             className={className}
                //             //Shortcut
                //             onDoubleClick={()=> {this.allFinished()}}
                //             src={imgURL + this.props.slide.queries[i].img}/>
                    
                //         </Zoom>
                //     )

                // }else{
                images.push(
                    <Zoom key={i} in={this.state.visible[i]}>
                
                        <img draggable="false" alt=""
                        className={className}
                        src={imgURL + this.props.slide.queries[i].img + "?v=" + this.props.expID + "&v2=" + this.props.uuid}/>
                
                    </Zoom>
                    )
                // }

            } 
            return images
    }

    sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    async mapImageLoaded(){
        await this.sleep(200)
        this.setState({loaded: true})
    }

    render() {
        if(!this.props.slide) return (<div className={this.props.classes.mapImage}></div>)

        const showImages = this.props.expDialogOpen || (this.state.loaded && this.state.allSetUp)
        return (
            <div className={this.props.classes.root}>

                <div className={this.props.classes.mainPanel}>
                    {!showImages ? <div className={this.props.classes.mapImage}/> :
                        <div className={this.props.classes.queryImagesPanel}>
                            
                            {this.renderQueryImages()}
                        </div>
                    }
                    <Zoom in={showImages}>
                        <img onLoad={() => this.mapImageLoaded()} draggable="false" alt="" id="mapImage" onClick={this.checkClick.bind(this)} className={this.props.classes.mapImage} src={imgURL + this.props.slide.map.img}></img>
                    </Zoom>
                </div>
            </div>
        )
    }
} 

const styles = {
    root :{
        margin: "auto",
        height: "90vh"
        //marginBottom: 0,
    },
    queryImagesPanel: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        marginTop: "0.3vh",
        
    },

    mainPanel: {
        width: "100vw",
        display: "flex",
        margin: "auto"
    },
    mapImage: {
        margin: "auto",
        marginLeft:0,
        marginTop: 0,
        height: "85vmin", 
        width:  "85vmin", 
    },
    imageActive:{
        height: "15vmin", 
        width: "15vmin", 
        margin: "auto",
        marginTop: "0.2vh",
        marginBottom: "2vmin",
        boxShadow: "0px 0px 0px 0.3vh gray"
    },
    image:{
        height: "15vmin", 
        width: "15vmin", 
        margin: "auto",
        marginTop:  "0.2vh",
        marginBottom: "2vmin",
        boxShadow: "0px 0px 0px 0.3vh black"
    },

    imageSolved:{
        height: "15vmin", 
        width: "15vmin", 
        margin: "auto",
        marginTop:  "0.2vh",
        marginBottom: "2vmin",
        boxShadow: "0px 0px 0px 0.3vh green"
    },


};

function mapStateToProps(state){
    return {
      chosenContent: state.chosenContent,
    }
  }

export default connect(mapStateToProps)(withStyles(styles)(SearchExpCard));

import React from 'react';
import { withStyles, Button} from '@material-ui/core';
import {connect} from "react-redux";
//import {config} from "../../API/config";
import exp1img_en from '../../static/images/exp_1_en.jpg';
import exp1img_de from '../../static/images/exp_1_de.jpg';
import {compareLauncher} from "../../static/text";
import {action_set_content, action_set_experiment_type} from "../../State/actions";
import { ContentEnum,  ExpTypeEnum} from '../../util/enums';


class CompareLauncher extends React.Component {


    startCompareExp(){
        this.props.dispatch(action_set_experiment_type(Object.assign(ExpTypeEnum.Compare, {})));
        this.props.dispatch(action_set_content(Object.assign(ContentEnum.CompareExp, {})));
    }


    renderImage(){
        //console.log(this.props.language)
        if(String(this.props.language) === "de"){
            return (<img src={exp1img_de} alt="" className={this.props.classes.teaserImg}></img>)
        }else if (String(this.props.language) === "en") {
            return (<img src={exp1img_en} alt="" className={this.props.classes.teaserImg}></img>)
        }
    }
    
    render() {
       
        return (
            <div className={this.props.classes.root}>
               
                <p className={this.props.classes.header}>
                    {compareLauncher.headline[this.props.language]}
                </p>
                <p className={this.props.classes.text}>
                    {compareLauncher.firstText[this.props.language]}
                </p>
                {this.renderImage()}
                <Button onClick={this.startCompareExp.bind(this)} className={this.props.classes.startButton}>
                    {compareLauncher.button[this.props.language]}
                </Button>

                   
            </div>
        )
    }
} 


const styles = {
    root :{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        width: "100vw",
        whiteSpace: "pre-wrap",
    },

     text : {
        fontSize: "3vmin",
        marginTop: "0%",
        marginLeft: "7%",
        marginRight: "7%",
        //marginBottom: "1%",
    },
    startButton : {
        fontSize: "3vmin",
        margin: "auto",
        width: "25%",
        position: "absolute", 
        right: 10, 
        bottom: 10,
    },
    header : {
        margin: "auto",
        marginLeft: "7%",
        marginTop: "4vmin!important",
        marginBottom: "4vmin",
        fontSize: "5vmin",
        fontWeight: "bold"
    },
    teaserImg: {
        margin: "auto",
        marginTop: "4vmin",
        //margin: "auto",
        //marginLeft: "20%",
        width: "50%"
    }
};

function mapStateToProps(state){
    return {
      language: state.language,

    }
  }

export default connect(mapStateToProps)(withStyles(styles)(CompareLauncher));
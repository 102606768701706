// export const config = {
//     "apiURL": "api",
//     "imgURL": "images"
// }

export const config = {
    // "apiURL": `/api`,
    // "imgURL": "/images/"
    // "apiURL": `http://11.11.11.6:5555`,
    // "imgURL": "http://11.11.11.6:5555/images"
    "apiURL": `https://experiment.visual-computing.com/api`,
    "imgURL": "https://experiment.visual-computing.com/images"
}

import React from 'react';

import { withStyles, Button} from '@material-ui/core';
import { createStore } from 'redux';
import Reducers from '../State/reducers';
import {action_set_language} from "../State/actions";
import { connect } from "react-redux";

export const store = createStore(Reducers);

/* const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: "rgba(0, 0, 0, 0.25)",
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  })); */

class Header extends React.Component {

    constructor(props){
        super(props);
        
        const checked = localStorage.getItem("las.us.language") === "de"
        //console.log(checked)
        this.state = {
            isDeutsch: checked
        }
      }

    switchLanguage(){

        localStorage.setItem("las.us.language", this.state.isDeutsch ? "en" : "de")
        this.setState({isDeutsch: !this.state.isDeutsch})
        this.props.dispatch(action_set_language(Object.assign(this.state.isDeutsch ? "en" : "de", {})));
    }
    
    render() {
        
        const enS = this.state.isDeutsch ? this.props.classes.textInactive : this.props.classes.textActive
        const deS = !this.state.isDeutsch ? this.props.classes.textInactive : this.props.classes.textActive
        //console.log(enS, deS)

        return (
            
            <div className={this.props.classes.root}>
                <Button className={deS} onClick={() => this.switchLanguage()}>DE</Button>
                <Button className={enS} onClick={() => this.switchLanguage()}>EN</Button>
            </div>
        )
    }
} 

const styles = {
    root :{
       position: "absolute",
       right: "7%",  
       top: "0.5%",
       display: "flex",
       height: "3vmin"
    },
    textActive: {
        color: "blue",
        //maxWidth: "5vw",
        minWidth: "0",
        fontSize: "2.2vmin",
        backgroundColor: 'rgba(0,0,0,.0)', //"white",
        margin: 0,
        padding: 0,
        marginLeft: "3vw", 
    },
    textInactive: {
        //maxWidth: "5vw%",
        minWidth: "0",
        fontSize: "2.2vmin", 
        backgroundColor: 'rgba(0,0,0,.0)', // "white",
        margin: 0,
        padding: 0,
        marginLeft: "3vw",
    }
};

function mapStateToProps(state, ownProps) {
    return {
        chosenContent: state.chosenContent
    }
  }

export default connect(mapStateToProps)(withStyles(styles)(Header));



import { createMuiTheme } from '@material-ui/core/styles';

//const font = 'Gugi';

const theme = createMuiTheme({
    
    palette: {
        type: "light",
        primary: {
            light: '#ce422b',
            main: '#4e91ca',
            dark: '#620000',
        },
        secondary: {
            light: '#4e91ca',
            main: '#006499',
            dark: '#003a6a',
        },
        action: {
            active: '#4e91ca',
        },
        background: {
            paper: '#fff',
            main: '#DCDCDC',
        },
        text: {
            primary: '#000'
        }
        // error: will use the default color
    },
    typography: {
        //fontFamily: font,
        fontSize: 12,
    },
    overrides: {
        MuiFormLabel: {
            root: {
                // margin: "0 0 10px 10px",
            }
        },
        MuiInputBase: {
            input: {
                padding: "12px",
                width: "100%"
            },
            inputMarginDense: {
                padding: "5px 5px 12px 5px",
            }
        },
        
        MuiInputAdornment: {
          positionEnd: {
            marginLeft: 0
          }
        },

        MuiButton: {
            root:{
                background:  "rgb(238,238,239)",
                "&:hover": {
                    background: "rgb(120,120,120)",
                },
                "&:active": {
                    background: "rgb(90,90,90)",
                },
                "&:disabled": {
                    background: "rgb(255,255,255)"
                },
                "&:focus": {
                    background: "rgb(238,238,239)"
                },
            },
        },
        
        MuiFab:{
            root:{
                "&:disabled": {
                    background: "#888"
                },
            },
            secondary:{
                "&:disabled": {
                    "&:hover": {
                        backgroundColor: "#888",
                        cursor: "default"
                    },
                },
                backgroundColor: "rgb(17, 82, 137)",
            }
        },
        MuiCollapse: {
            // entered: {
            //     height: "220px !important",
            // }
        },
        MuiDivider:{
            middle:{
                margin: "20px 0",
            }
        },
        MuiDialog:{
            paper:{
                paddingTop: "15px"
            }
        }
    }
});

export default theme;

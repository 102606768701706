
import { actionTypes } from './actions';
import {ContentEnum} from "../util/enums"
import {generateUUID} from '../util/uidGenerator';
let defaultLanguage = localStorage.getItem("las.us.language")

if (!defaultLanguage){
  defaultLanguage = "de"
}

const initalState = {
  email: null,
  expType: null, 
  chosenContent: ContentEnum.Landing,
  language: defaultLanguage,
  uid: generateUUID()
}

export default function reducer(state = initalState, action) {
  switch (action.type) {
    case actionTypes.SET_EMAIL:
      return Object.assign({}, state, {
        email: action.payload.email
      });
    case actionTypes.SET_CONTENT:
      return Object.assign({}, state, {
        chosenContent: action.payload.chosenContent
      });
    case actionTypes.SET_EXPERIMENT_TYPE:
        return Object.assign({}, state, {
          expType: action.payload.expType
        });
    case actionTypes.SET_LANGUAGE:
          return Object.assign({}, state, {
            language: action.payload.lang
          });
    default:
      return state
  }
}



import React from 'react';
import { withStyles} from '@material-ui/core';
import {connect} from "react-redux";
import Header from "./Header"
import CompareExpShell from "./CompareExp/CompareExpShell"
import CompareLauncher from "./CompareExp/CompareLauncher"
import SearchExpShell from "./SearchExp/SearchExpShell"
import SearchLauncher from "./SearchExp/SearchLauncher"
import LandingComp from "./Landing/LandingComp"
import EndingComp from "./Ending/EndingComp"
import { ContentEnum } from '../util/enums';



class Root extends React.Component {
    showCurent(){

        // eslint-disable-next-line
        if(this.props.chosenContent == ContentEnum.CompareExp){
            return (<CompareExpShell onMouseDown={(e) => e.preventDefault()} />)
           // eslint-disable-next-line 
        } else if( (this.props.chosenContent == ContentEnum.SearchExp)){
            return (<SearchExpShell onMouseDown={(e) => e.preventDefault()}/>)
            // eslint-disable-next-line
        } else if( (this.props.chosenContent == ContentEnum.SearchExpTut)){
            return (<SearchLauncher onMouseDown={(e) => e.preventDefault()} />)
            // eslint-disable-next-line
        } else if( (this.props.chosenContent == ContentEnum.CompareExpTut)){
            return (<CompareLauncher onMouseDown={(e) => e.preventDefault()} />)
            // eslint-disable-next-line
        } else if( (this.props.chosenContent == ContentEnum.CompareExpTut)){
            return (<EndingComp/>)
            // eslint-disable-next-line
        }else if(this.props.chosenContent == ContentEnum.Landing){
                return (<LandingComp onMouseDown={(e) => e.preventDefault()}/>)
        } else {
            return (<EndingComp/>)//(<LandingComp/>)
        }
    }

    render() {

        
        return (
            
        <div className={this.props.classes.root}>
            <Header/>
            {this.showCurent()}
        </div>
                    
        )
    }
} 

const styles = {
    root :{
        overflow: "auto",
        height: "100vh"
    }

};

function mapStateToProps(state){
    return {
      chosenContent: state.chosenContent,
    }
  }

export default connect(mapStateToProps)(withStyles(styles)(Root));

import React from 'react';
import { withStyles } from '@material-ui/core';
import {APIgetSearchExp, APIsolveSearchExp} from "../../API/ApiRequestor";

import FinishedExpDialog from "./FinishedExpDialog"
import SearchExpCard from "./SearchExpCard"
import {connect} from "react-redux";
import { ContentEnum} from '../../util/enums';
import {action_set_experiment_type, action_set_content} from "../../State/actions";
import {searchExperiment} from "../../static/text";

class SearchExpShell extends React.Component {
    constructor(props){
        super(props);
      
        this.state = {
            currentIndex: 0,
            expiriments: [],
            expDialogOpen: false,
        }
      }

   
    async componentWillMount(){
        
        const exps2 = await APIgetSearchExp()
        //console.log(exps2)
        await this.setState({expiriments: exps2})
    }

    answerExperiment(duration){
        const expID = this.state.expiriments[this.state.currentIndex]["id"]
        //console.log(this.props.uid, expID, duration, this.state.currentIndex)
        APIsolveSearchExp(this.props.uid, expID, duration, this.state.currentIndex)
        this.setState({
            expDialogOpen: true
        }) 
    }

    finalizeExperiment(){

        this.props.dispatch(action_set_experiment_type(null));
        this.props.dispatch(action_set_content(Object.assign(ContentEnum.Ending, {})));
        
        this.setState({
            currentIndex: 0, 
            expiriments: [],
            expDialogOpen: false
        });
    }


    async goNext(){
        //console.log("next", "  ", this.state.currentIndex + "  " + (this.state.expiriments.length - 1))
        const currentIndex = this.state.currentIndex

        if(currentIndex === (this.state.expiriments.length - 1)){
            this.finalizeExperiment()
            return
        }
    
        await this.setState({
            currentIndex: (currentIndex + 1),

            expDialogOpen: false
        })
    }


    render() { 
        let expID = null
        const currentIndex = this.state.currentIndex

        if (this.state.expiriments[currentIndex])
            expID = this.state.expiriments[currentIndex]["id"]

        const slide = this.state.expiriments[currentIndex]
        const headline = currentIndex === 0 ? searchExperiment.headlineTrial[this.props.language] : `${searchExperiment.headline[this.props.language]} (${currentIndex}/${this.state.expiriments.length-1})`
        // const nextButtonText = currentIndex === (this.state.expiriments.length - 1) ? searchExperiment.finish[this.props.language] : searchExperiment.next[this.props.language]
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.status}>
                    <span className={this.props.classes.statusText}>{headline}</span>
                </div>
                <div className={this.props.classes.mainPanel}>
                    <SearchExpCard uuid={this.props.uid} expID={expID} expDialogOpen={this.state.expDialogOpen} slide={slide} answerExperiment={this.answerExperiment.bind(this)}/>
                    {/* <Button disabled={this.state.nextDisabled} ref={this.nextButton} className={this.props.classes.nextButton} onClick={this.goNext.bind(this)}>
                                {nextButtonText}
                    </Button> */}
                </div>
                <div className={this.props.classes.control}>
                   
                </div>
                <FinishedExpDialog  expIndex={this.state.currentIndex} open={this.state.expDialogOpen} handleClose={this.goNext.bind(this)}/>
            </div>
        )
    }
} 

const styles = {
    root :{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        marginBottom: "0vh",
    },
    status:{
        height: "10vh",
        margin: "auto", 
        marginLeft: "0vh",
    },
    statusText:{
        fontSize: "3vmin",
        margin: "auto", 
        marginLeft: "7vh",
        lineHeight: "10vh"
    },
    mainPanel: {
        height: "90vh",
        //width: "100vw",
        display: 'flex',
        margin: "auto",
        //marginBottom: "0vh",
    },
};

function mapStateToProps(state){
    return {
        language: state.language,
        uid: state.uid
    }
  }

export default connect(mapStateToProps)(withStyles(styles)(SearchExpShell));

import React from 'react';
import { Box, withStyles, Radio, RadioGroup, FormControlLabel, FormControl, Zoom} from '@material-ui/core';
import {connect} from "react-redux";
import {config} from "../../API/config";

const imgURL = config.imgURL;
class CompareExpCard extends React.Component {

    constructor(props){
        super(props);
      
        this.state = {
            coin: "head",
            selected: -1,
            leftImage: "",
            rightImage: "",
            timeStart: Date.now(),
            imagesLoaded: [false, false]
        }
      }

    async radioChanged(e){

        let radioSelection = Number(e.target.value)
        this.setState({selected: radioSelection})
        await this.props.toggleNextButton(false)
        this.props.nextButton.current.focus()
    }

    componentDidUpdate(prevProps){

        if(!prevProps.slide && this.props.slide){
            this.genExp()
            return
        }

        if(!prevProps.slide || !this.props.slide) return
        if(prevProps.slide.id !== this.props.slide.id){
            const duration = Date.now() - this.state.timeStart

            let radioSelection = this.state.selected
            if(this.state.coin === "head"){
                radioSelection = 2 - radioSelection
            }

            this.props.answerExperiment(radioSelection, duration, this.state.selected)

            //reset Exp
            this.genExp()
        }
    }


    async genExp(){
        const coin = Math.random() > 0.5 ? "head": "tail"
        //console.log("gen EXP", "  ", "coin_" +  coin)
        const timeStart = Date.now()

        let leftImage
        let rightImage

        if(coin === "head"){
            leftImage = imgURL  + this.props.slide.B + "?v=" + Date.now()
            rightImage = imgURL  + this.props.slide.A + "?v=" + Date.now()
        }else{
            leftImage = imgURL + this.props.slide.A + "?v=" + Date.now()
            rightImage = imgURL + this.props.slide.B + "?v=" + Date.now()
        }

        await this.setState({
            coin,
            timeStart,
            leftImage,
            rightImage,
            selected: -1,
            imagesLoaded: [false, false]
        })

        //console.log(this.state)


    }

    async sleep(milliseconds) {
        return await new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    async selectThroughImage(val){
        let radioSelection = val
        this.setState({selected: radioSelection})
        await this.props.toggleNextButton(false)
        this.props.nextButton.current.focus()
    }

    async setImageLoaded(i){

        const stat = this.state.imagesLoaded
        stat[i] = true
        
        //await this.sleep(200 + i)
        this.setState({imagesLoaded: stat})
    }
    
    render() {
        const imagesLoaded = this.state.imagesLoaded[0] && this.state.imagesLoaded[1]
        return (
            <div className={this.props.classes.root}>

                    <div className={this.props.classes.imagesPanel}>
                        <Zoom in={imagesLoaded}>
                            <img onLoad={() => this.setImageLoaded(0)} alt="" draggable="false" onClick={()=> this.selectThroughImage(0)} className={this.props.classes.descriptionImageLeft} src={this.state.leftImage}></img>
                        </Zoom>
                        <Zoom in={imagesLoaded}>
                            <img onLoad={() => this.setImageLoaded(1)} alt="" draggable="false" onClick={()=> this.selectThroughImage(2)} className={this.props.classes.descriptionImageRight} src={this.state.rightImage}></img>
                        </Zoom>
                    </div>
                
                    <FormControl className={this.props.classes.radioButtons}>
                    
                        <RadioGroup onChange={this.radioChanged.bind(this)} value={String(this.state.selected)} defaultValue="1" row aria-label="compare" name="row-radio-buttons-group">
                            <Box>
                                <FormControlLabel className={this.props.classes.smallRadioButton} value="0" control={<Radio />} label="" />
                            </Box>
                            <Box>
                                <FormControlLabel className={this.props.classes.smallRadioButton} value="1" control={<Radio />} label="" />
                            </Box>
                            <Box>
                                <FormControlLabel className={this.props.classes.smallRadioButton} value="2" control={<Radio />} label="" />
                            </Box>
                        </RadioGroup>
                    </FormControl>
            </div>
        )
    }
} 

const styles = {
    root :{
        margin: "auto",
        marginTop: 0,
        
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    
    descriptionImageLeft: {
        width: "calc(min(80vh, 45vw))",
        margin: "auto",
        //marginLeft:0,
        cursor: "pointer"
    },
    descriptionImageRight: {
        width: "calc(min(80vh, 45vw))",
        margin: "auto",
        //marginRight:0,
        cursor: "pointer"
    },
    imagesPanel: {
        marginTop: 0,
        display: "flex",
        width: "100%"
    },
    radioButtons:{
        justifyContent: 'center',
        alignItems: 'center',
        margin: "auto",
    },
    smallRadioButton: {
        "& svg": {
          width: "4vh",
          height: "4vh",
        },
        marginLeft:  "calc(0.03*(100vw - 2*min(20vh, 55vw)))",
        marginRight: "calc(0.03*(100vw - 2*min(20vh, 55vw)))",
      }
};

function mapStateToProps(state){
    return {
      chosenContent: state.chosenContent,
    }
  }

export default connect(mapStateToProps)(withStyles(styles)(CompareExpCard));
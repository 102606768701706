export const ContentEnum = Object.freeze({
                                    "Landing":1,
                                    "CompareExp":2,
                                    "SearchExp":3,
                                    "CompareExpTut": 4,
                                    "SearchExpTut": 5,
                                    "Ending": 6
                                    })

export const ExpTypeEnum = Object.freeze({
                                        "Search":1,
                                        "Compare":2,
                                    })


import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import Root from "./components/Root"
import 'semantic-ui-css/semantic.min.css'

import theme from './components/theme.js';
import ErrorBoundary from "./components/ErrorBoundary";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import Reducers from './State/reducers';
export const store = createStore(Reducers);

class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <ErrorBoundary>
              <div className="App">
                <Root/>
              </div>
            </ErrorBoundary>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;

export const landing = {
    "headline": {
        "en": "Prof. Dr. Kai Barthel - Visual Computing Group, HTW Berlin",
        "de": "Prof. Dr. Kai Barthel - Visual Computing Gruppe der HTW Berlin" 
    },
    "subHeadline": {
        "en": "The Image Sorting Experiment",
        "de": "Das Bildsortierungs-Experiment" 
    },
    "firstText": {
        "en": "We want to find out what kind of sortings help to find images more easily. \nOur experiment consists of two parts: First you compare image sortings, then you are supposed to find images. In total, it will take no more than 10 minutes.",
        "de": "Wir wollen herausfinden, welche Sortierungen helfen, Bilder leichter zu finden. \nUnser Experiment besteht aus zwei Teilen: Zuerst vergleichst du Bildsortierungen, dann sollst du Bilder finden. Insgesamt dauert es nicht länger als 10 Minuten." 
    },
    "secondText": {
        "en": "Unfortunately, our raffle among the participants has ended.\nBut we are still happy if many participate in our experiment.",
        "de": "Leider ist unsere Verlosung unter den Teilnehmern beendet.\nAber wir freuen uns weiterhin, wenn viele an unserem Experiment teilnehmen." 
    },
    //"thirdText": {
    //    "en": "All email addresses will of course not be used for any further purpose and will be deleted after the raffle.",
    //    "de": "Alle Emailadressen werden selbstverständlich für keine weiteren Zwecke verwendet und nach der Verlosung gelöscht." 
    //},
    "button":{
        "en": "Take part in the experiment", 
        "de": "Am Experiment teilnehmen"
    },
    "impressum":{
        "en": "Impressum", 
        "de": "Impressum"
    },
    "privacy":{
        "en": "Privacy Policy", 
        "de": "Datenschutzerklärung"
    }
}


export const compareLauncher = {
    "headline": {
        "en": "Part 1: Perceived Sorting Quality",
        "de": "Teil 1: Empfundene Sortierqualität" 
    },
    "firstText": {
        "en": "Two image sortings will be shown in the following.\nDecide which one you like better. Better here means that the images are arranged in a more useful way, which gives you a better overview and therefore you would find searched images faster.\n\nChoose the size of your browser window so that you can clearly see the sortings. Some sortings may not look so nice, but try to choose the better one anyway. Take your time, it doesn't matter how fast you are.\nMark the sorting of your choice as shown below.",
        "de": "Im Folgenden werden jeweils zwei Bildsortierungen gezeigt.\nEntscheide welche dir besser gefällt. Besser bedeutet hier, dass die Bilder sinnvoller angeordnet sind, wodurch du einen besseren Überblick hast und gesuchte Bilder daher schneller finden würdest.\n\nWähle die Größe deines Browserfensters so, dass du die Sortierungen gut erkennen kannst. Manche Sortierungen sehen vielleicht nicht so schön aus, aber versuche trotzdem, die bessere auszuwählen. Lass dir Zeit, die Geschwindigkeit spielt keine Rolle. Markiere die Sortierung deiner Wahl so wie unten dargestellt." 
    },
    //"secondText": {
    //    "en": "In the following you will be shown two sortings at a time. Enlarge your browser window so that you can see the sortings clearly. Decide whether you prefer the sorting on the left or on the right side. Mark the one you prefer. \nSome sortings might not look so nice, but still try to select the better one. If you can't decide or find both the same, select the box in the middle.",
    //    "de": "Markiere unten das Auswahlfeld deiner Wahl. Wenn du beide gleich empfindest, wähle das Feld in der Mitte aus. Lass dir Zeit, es spielt keine Rolle, wie schnell du bist." 
    //},
    //"thirdText": {
    //    "en": "Take your time, it does not matter how fast you are.",
    //    "de": "Lass dir Zeit, es spielt keine Rolle, wie schnell du bist." 
    //},
    "button":{
        "en": "Start", 
        "de": "Start"
    }
}

export const compareExperiment = {
    "headline": {
        "en": "Select the sorting that you find clearer",
        "de": "Wähle die Sortierung, die du übersichtlicher findest" 
    },
    "next": {
        "en": "Next",
        "de": "Weiter" 
    },
    "finish": {
        "en": "Finish",
        "de": "Beenden" 
    },
}

export const searchLauncher = {
    "headline": {
        "en": "Part 2: Search Time",
        "de": "Teil 2: Suchzeit" 
    },
    "firstText": {
        "en": "Now you are supposed to find four images in the sortings. Try to locate the images you are looking for as fast as possible and click on them. We measure how long this takes. If you ever click a wrong image, it doesn't matter.",
        "de": "Nun sollst du vier Bilder in den Sortierungen finden. Versuche, die gesuchten Bilder so schnell wie möglich zu finden und sie anzuklicken. Wir messen wie lange das dauert. Falls du mal auf ein falsches Bild klickst, macht das nichts."
    },
    "secondText": {
        "en": "If you want to take a break, you can do so whenever you have found a group of four images, but please not while you are searching.",
        "de": "Wenn du eine Pause machen möchtest, kannst du das immer dann tun, wenn du eine Gruppe von vier Bildern gefunden hast, aber bitte nicht während der Suche." 
    },
     "thirdText": {
        "en": "We'll start with a trial round so you get to know the principle.",
        "de": "Wir fangen mit einer Proberunde an, damit du das Prinzip kennenlernst." 
    }, 
    "button":{
        "en": "Start", 
        "de": "Start"
    }
} 

export const searchExperiment = {
    "headline": {
        "en": "Quickly click the searched images!",
        "de": "Klicke schnell auf die gesuchten Bilder!"
    },
    "headlineTrial": {
        "en": "Trial round: Quickly click the searched images!",
        "de": "Proberunde: Klicke schnell auf die gesuchten Bilder!"
    },
    "dialogTitle": {
        "en": "Good job!",
        "de": "Gut gemacht!"
    },
    "dialogText": {
        "en": "Click \"Next\" to start the next search",
        "de": "Klicke auf \"Weiter\", um die nächste Suche zu starten."
    },
    "dialogText2": {
        "en": "\nFrom now on you will be timed.",
        "de": "\nAb jetzt wird die Zeit gestoppt."
    },
    "next": {
        "en": "Next",
        "de": "Weiter" 
    }
}

export const ending = {
    "headline": {
        "en": "Thank you very much for your help!",
        "de": "Vielen Dank für deine Mithilfe!" 
    },
    "firstText": {
        "en": "To be informed about the result of this experiment,\nplease enter your email address:",
        "de": "Um über das Ergebnis dieses Experiments informiert zu werden,\ngib bitte deine Emailadresse an:"
    },
    "partipateOption": {
        "en": "I would like to participate in the raffle.",
        "de": "Ich möchte an der Verlosung teilnehmen." 
    },
    "thirdText": {
        "en": "I would like be informed about the result of this experiment.",
        "de": "Ich möchte über das Ergebnis informiert werden." 
    },
    "sendButton": {
        "en": "Send",
        "de": "Abschicken"
    },
    "againButton":{
        "en": "Start over", 
        "de": "Neu beginnen"
    },
    "moreButton":{
        "en": "Learn more about our research", 
        "de": "Mehr über unsere Forschung"
    },
    "impressum":{
        "en": "Impressum", 
        "de": "Impressum"
    },
    "privacy":{
        "en": "Privacy Policy", 
        "de": "Datenschutzerklärung"
    }
}

export const emailDialog = {
    "headline": {
        "en": "Thank you!",
        "de": "Vielen Dank!" 
    },
    
    "errorHeadline": {
        "en": "Invalid email address",
        "de": "Ungültige Emailadresse"
    },
    "text1": {
        "en": "The email address ",
        "de": "Die Emailadresse " 
    },
    "text2": {
        "en": " has been saved succesfully.",
        "de": " wurde erfolgreich gespeichert." 
    },
    "errorText2": {
        "en": " appears to be invalid.",
        "de": " scheint leider ungültig zu sein." 
    }
}
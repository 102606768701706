import * as React from 'react';
import {withStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import {emailDialog} from "../../static/text";
import {connect} from "react-redux";

class SendEmailDialog extends React.Component{

  render(){
    const title = this.props.ok ? emailDialog.headline[this.props.language] : emailDialog.errorHeadline[this.props.language]
    
    const text = this.props.ok ? (emailDialog.text1[this.props.language] + this.props.email + emailDialog.text2[this.props.language]) : (emailDialog.text1[this.props.language] + this.props.email + emailDialog.errorText2[this.props.language])
    //console.log(this.props.ok)
    return (
         
          <Dialog
            fullWidth
            maxWidth="sm"
            open={this.props.open}
            onClose={this.props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className={this.props.classes.title} id="alert-dialog-title">
              {title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={this.props.classes.text} id="alert-dialog-description">
                {text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleClose} className={this.props.classes.text}  autoFocus disableFocusRipple={true}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
      );
  }
  
}

const styles = {
  text : {
    fontSize: "2vh",
  },
  title : {
      '& h2': {
        fontSize: "3vh",
      }
  },
}

function mapStateToProps(state){
  return {
      language: state.language
  }
}

export default connect(mapStateToProps)(withStyles(styles)(SendEmailDialog));

import React from 'react';
import { withStyles, TextField, Button, Checkbox, FormControlLabel} from '@material-ui/core';
import {connect} from "react-redux";
import SendEmailDialog from "./SendEmailDialog"
import {APIenterEmail} from "../../API/ApiRequestor";
import {ending} from "../../static/text";
import {action_set_content} from "../../State/actions";
import { ContentEnum} from '../../util/enums';




class EndingComp extends React.Component {

    constructor(props){
        super(props);
      
        this.state = {
            raffle: false,
            email: "",
            news: false,
            emailDialogOpen: false,
            emailOk: false,
            sendDisable: false,
        }
      }

    onUnload(e){
        e.preventDefault()
        e.returnValue = ''
    }

    componentDidMount(){

        window.addEventListener('beforeunload', this.onUnload)
    }

    async sendEmailStatus(){
        // this.props.dispatch(action_set_content(Object.assign(ContentEnum.CompareExpTut, {})));
        //APICall
        if(this.state.raffle || this.state.news){
            //console.log(this.state.email.includes("@"), this.state.email.includes("."), this.state.email.indexOf(' '))
            if(this.state.email.includes("@") && this.state.email.includes(".") && (this.state.email.indexOf(' ') === -1)){
                //console.log("send", this.state.email, this.state.raffle, this.state.news)
                this.setState({emailDialogOpen: true, emailOk: true, sendDisable: true})
                APIenterEmail(this.props.uid, this.state.email, Number(this.state.raffle), Number(this.state.news))
                window.removeEventListener("beforeunload", this.onUnload);
            }else{
                this.setState({emailDialogOpen: true, emailOk: false})
            }
        }
            
    }

    closeWebsite(){
        window.open("about:blank", "_self");
        window.close();
    }

    refToVisComputing(){
        //this.sendEmailStatus()
        window.open("https://visual-computing.com/")
    }

    returnToLanding(){
        if(!this.state.sendDisable){
            this.sendEmailStatus()
        }
        window.location.reload()
        // this.props.dispatch(action_set_content(Object.assign(ContentEnum.Landing, {})));
    }

    saveEmail(e){
        //console.log(e.target.value, !this.state.sendDisable)
        this.setState({email: e.target.value})
    }

    
    render() {
        return (
            <div className={this.props.classes.root}>
                
                <span className={this.props.classes.header}>
                    {ending.headline[this.props.language]}
                </span>
                        
                   
                <div className={this.props.classes.inputDiv}>
                    <span className={this.props.classes.text}>
                        {ending.firstText[this.props.language]}
                    </span>

                    {/* <FormControlLabel  className={this.props.classes.checkLabel}
                        fontSize="4vh" control={<Checkbox className={this.props.classes.checkbox} checked={this.state.raffle} 
                        onChange={(e) => this.setState({raffle: e.target.checked})}/>} label={ending.partipateOption[this.props.language]} />
                     */}
                    <FormControlLabel className={this.props.classes.checkLabel}
                        fontSize="4vh" control={<Checkbox className={this.props.classes.checkbox} checked={this.state.news} 
                        onChange={(e) => this.setState({news: e.target.checked})}/>} label={ending.thirdText[this.props.language]} />
                    <div  className={this.props.classes.emailDiv}>
                        <TextField value={this.state.email} onChange={this.saveEmail.bind(this)} className={this.props.classes.emailTextField}  label="Email" variant="outlined"/>
                        <Button disabled={this.state.sendDisable} onClick={() => this.sendEmailStatus()} className={this.props.classes.sendButton}>
                            {ending.sendButton[this.props.language]}
                        </Button>
                    </div>
                </div>
                <div className={this.props.classes.buttonDiv}>
                    <Button onClick={this.returnToLanding.bind(this)} className={this.props.classes.startButton}>
                        {ending.againButton[this.props.language]}
                    </Button>
                    <Button onClick={this.refToVisComputing.bind(this)} className={this.props.classes.startButton}>
                        {ending.moreButton[this.props.language]}
                    </Button>
                </div>
                <div className={this.props.classes.impressum}>
                    <a target="_blank" className={this.props.classes.link} href="https://visual-computing.com/impressum/">{ending.impressum[this.props.language]}</a>
                    <a target="_blank" className={this.props.classes.link} href="https://www.htw-berlin.de/datenschutz/">{ending.privacy[this.props.language]}</a>
                </div>
                <SendEmailDialog open={this.state.emailDialogOpen} email={this.state.email} ok={this.state.emailOk} handleClose={()=> this.setState({emailDialogOpen: false})}/>
            </div>
        )
    }
} 

const styles = {
    root :{
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingLeft: "7%",
        paddingRight: "7%",
        paddingTop: "3%",
        whiteSpace: "pre-wrap",
    },
    inputDiv:{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "50%"
    },
    emailDiv: {
        display: "flex",
        height: "30%"
    },
    checkLabel: {
        "& span": {
            fontSize: "3vh",
            lineHeight: "4vh",
          }
    },
    checkbox:{
        marginLeft: "0",

        "& svg": {
            width: "4vh",
            height: "4vh",
            //maringRight: "2%"
          }
    },
     buttonDiv :{ // start button
        display: "flex",
        width: "100%",
        height: "20%",
        marginLeft: "0",
        marginBottom: "5%"
     },
     text : {
        fontSize: "3vh",
        lineHeight: "4vh",
        margin: "auto",
        marginLeft: 0,
        paddingBottom: "0%",
        textAlign: "justify"
    },
    startButton : {
        fontSize: "2.5vh",
        margin: "auto",
        width: "45%",
    },
    header : { 
        height: "10%",
        marginLeft: 0,
        fontWeight: "bold",
        marginBottom: "3%",
        margin: "auto",
        fontSize: "4vh",
        lineHeight: "5.5vh",
    },

    emailTextField: {

        "& input": {
            fontSize: "3vh",
        },
        height: "4vh",
        width: "60%",
        margin: "auto",
        marginTop: "1%",
        marginLeft:0
    },
    sendButton: {
        height: "7.5vh",
        fontSize: "2.5vh",
        margin: "auto",
        marginTop: "1%",
        width: "25%"
    },
    link: {
        margin: "auto",
        marginRight: 0,
        fontSize: "0.7vw",
    },
    impressum:{
        width: "12%",
        display: "flex",
        position: "absolute",
        top: "95%",
        right: "7%"
    },

//    emailDescription: {
//        height: "60px",
//       width: "60%",
//        margin: "auto"
//    }

};

function mapStateToProps(state){
    return {
      language: state.language,
      uid: state.uid  
    }
  }

export default connect(mapStateToProps)(withStyles(styles)(EndingComp));
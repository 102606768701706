import * as React from 'react';
import {withStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import {searchExperiment} from "../../static/text";
import {connect} from "react-redux";

class FinishedExpDialog extends React.Component{
  
  render(){
    //console.log("index", this.props.expIndex)
    return (
          <Dialog
            fullWidth
            maxWidth="sm"
            disableEscapeKeyDown 
            disableBackdropClick
            open={this.props.open}
            onClose={this.props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle className={this.props.classes.title} id="alert-dialog-title">
              {searchExperiment.dialogTitle[this.props.language]}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={this.props.classes.text} id="alert-dialog-description">
                {searchExperiment.dialogText[this.props.language]}
                {this.props.expIndex > 0 ? "" :
                  <span style={{color: "green"}}>{searchExperiment.dialogText2[this.props.language]}</span>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleClose} className={this.props.classes.text}  autoFocus disableFocusRipple={true}>
                {searchExperiment.next[this.props.language]}
              </Button>
            </DialogActions>
          </Dialog>
      );
  }
  
}

const styles = {
  text : {
    fontSize: "2vh",
    whiteSpace: "pre-wrap",
  },
  title : {
      '& h2': {
        fontSize: "3vh",
      }
  },
}

function mapStateToProps(state){
  return {
      language: state.language
  }
}

export default connect(mapStateToProps)(withStyles(styles)(FinishedExpDialog));
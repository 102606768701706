
import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import {APIgetCompExp, APIsolveCompareExp} from "../../API/ApiRequestor";
// import FinishedExpDialog from "../SearchExp/FinishedExpDialog"
import CompareExpCard from "./CompareExpCard"
import {connect} from "react-redux";
import { ContentEnum} from '../../util/enums';
import {action_set_experiment_type, action_set_content} from "../../State/actions";
import {compareExperiment} from "../../static/text";


class ExpShell extends React.Component {
    constructor(props){
        super(props);
      
        this.state = {
            currentIndex: 0,
            expiriments: [],
            expDialogOpen: false,
            nextDisabled: true,
        }

        this.nextButton = React.createRef();
      }

 

    async componentWillMount(){
        
        const exps = await APIgetCompExp()
        //console.log(exps)

        this.setState({expiriments: this.shuffle(exps)})
    }

    shuffle(array) {
        //https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array

        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }

    async answerExperiment(answer, duration, click){
        const expID = this.state.expiriments[this.state.currentIndex -1]["id"]
        //console.log("answer", this.props.uid, expID, answer, duration, click)
        APIsolveCompareExp(this.props.uid, expID, duration,answer, click, this.state.currentIndex)

        if(this.state.currentIndex === (this.state.expiriments.length)){
            this.finalizeExperiment()
        }
    }

    async finalizeExperiment(){
        await this.setState({
            currentIndex: 0,
            expiriments: [],
            expDialogOpen: false
        });

        this.props.dispatch(action_set_experiment_type(null));
        this.props.dispatch(action_set_content(Object.assign(ContentEnum.SearchExpTut, {})));


    }


    async goNext(){
        //console.log("next", "  " + this.state.currentIndex + "  " + (this.state.expiriments.length - 1))
        const currentIndex = this.state.currentIndex
        
        
        await this.setState({
            currentIndex: (currentIndex + 1),
            nextDisabled: true
        })
    }

    toggleNextButton(){
        this.setState({nextDisabled: false})
    }


    render() {
        const currentIndex = this.state.currentIndex % this.state.expiriments.length
        const slide = this.state.expiriments[currentIndex]

        // const nextButtonText = currentIndex === (this.state.expiriments.length - 1) ? compareExperiment.finish[this.props.language] : compareExperiment.next[this.props.language]
        const nextButtonText = compareExperiment.next[this.props.language]
        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.upperTextDiv}>
                    <span className={this.props.classes.expHeader}>{compareExperiment.headline[this.props.language]} ({currentIndex + 1}/{this.state.expiriments.length})</span>
                </div>
                <CompareExpCard toggleNextButton={this.toggleNextButton.bind(this)} nextButton={this.nextButton} slide={slide} answerExperiment={this.answerExperiment.bind(this)}/>
                {/* <FinishedExpDialog open={this.state.expDialogOpen} handleClose={this.finalizeExperiment.bind(this)}/> */}
                
                <Button disableFocusRipple={true} disabled={this.state.nextDisabled} ref={this.nextButton} className={this.props.classes.nextButton} onClick={this.goNext.bind(this)}>
                    {nextButtonText}
                </Button>
            </div>
        )
    }
} 

const styles = {
    root :{
        margin: "auto",
        marginTop: "0",
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100%",
    },
    statusText:{
        fontSize: "3vmin",
    },
    upperTextDiv:{
        margin: "auto",
        marginTop: "0",
        display: 'flex',
        width: "100%",
        height: "10vmin",
    },
    expHeader:{
        fontSize: "4vmin",
        lineHeight: "4vmin",
        margin: "auto",
    },
    nextButton : {
        fontSize: "3vh",
        margin: "auto",
        width: "25%",
        position: "absolute", 
        right: 10, 
        bottom: 10
    },
};

function mapStateToProps(state){
    return {
        language: state.language,
        uid: state.uid
      }
  }

export default connect(mapStateToProps)(withStyles(styles)(ExpShell));

import React from 'react';
import { withStyles, Button} from '@material-ui/core';
import {connect} from "react-redux";
//import {config} from "../../API/config";
import exp2img from '../../static/images/exp_2.jpg';
import {searchLauncher} from "../../static/text";
import {action_set_content, action_set_experiment_type} from "../../State/actions";
import { ContentEnum,  ExpTypeEnum} from '../../util/enums';
//sconst imgURL = config.imgURL;

class SearchLauncher extends React.Component {

    startSearchExp(){
        this.props.dispatch(action_set_experiment_type(Object.assign(ExpTypeEnum.Search, {})));
        this.props.dispatch(action_set_content(Object.assign(ContentEnum.SearchExp, {})));
    }
    
    render() {
        return (
            <div className={this.props.classes.root}>
               
                <p className={this.props.classes.header}>
                    <div className={this.props.classes.headerText}> 
                    {searchLauncher.headline[this.props.language]}
                    </div>
                </p>
                <p className={this.props.classes.text}>
                    {searchLauncher.firstText[this.props.language]}
                </p>
                <img src={exp2img} className={this.props.classes.teaserImg} alt=""></img>
                <p className={this.props.classes.text}>
                    {searchLauncher.secondText[this.props.language]}
                </p>

                <p className={this.props.classes.text}>
                    {searchLauncher.thirdText[this.props.language]}
                </p> 
                <Button onClick={this.startSearchExp.bind(this)} className={this.props.classes.startButton}>
                    {searchLauncher.button[this.props.language]}
                </Button>
             
            </div>
        )
    }
} 


const styles = {
    root :{
        display: "flex",
        flexDirection: "column",
        height: "90%",
        overflow: "auto",
        width: "100vw",
        whiteSpace: "pre-wrap",
        //paddingTop: "4%",
    },

     text : {
        fontSize: "3vmin",
        marginTop: "0%",
        marginLeft: "7%",
        marginRight: "7%",
        marginBottom: "2vh",
    },
    startButton : {
        fontSize: "3vmin",
        margin: "auto",
        width: "25%",
        position: "absolute", 
        right: 10, 
        bottom: 10
    },
    header : {
        margin: "auto",
        marginLeft: "7%",
    },
    headerText : {
        fontSize: "5vmin",
        lineHeight: "15vmin",
        margin: "auto",
        fontWeight: "bold"
    },
    teaserImg: {
        margin: "auto",
        marginTop: "0%",
        height: "35vh"
    }
};

function mapStateToProps(state){
    return {
      language: state.language,

    }
  }

export default connect(mapStateToProps)(withStyles(styles)(SearchLauncher));
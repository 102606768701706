import {config} from "./config";
const axios = require('axios')
export const API_URL = config.apiURL;


export function APIgetCompExp(){
    const url = `${API_URL}/getCompExp`
    return axios({
          method: 'get',
          url: url
      })
      .then(response => {
        return response.data
      }).catch(function (error) {
        return error;
      })
  }


  export function APIgetSearchExp(){
    const url = `${API_URL}/getSearchExp`
    return axios({
          method: 'get',
          url: url
      })
      .then(response => {
        return response.data
      }).catch(function (error) {
        return error;
      })
  }

export function APIsolveSearchExp(uid, expid, time, index){
    const url = `${API_URL}/solveSearchExp?uid=${uid}&expid=${expid}&time=${time}&index=${index}`
    return axios({
          method: 'post',
          url: url
      })
      .then(response => {
        return response.data
      }).catch(function (error) {
        return error;
      })
}

export function APIsolveCompareExp(uid, expid, time, avsb, click, index){
  const url = `${API_URL}/solveCompareExp?uid=${uid}&expid=${expid}&time=${time}&avsb=${avsb}&click=${click}&index=${index}`
  return axios({
        method: 'post',
        url: url
    })
    .then(response => {
      return response.data
    }).catch(function (error) {
      return error;
    })
}

export function APIenterEmail(uid, email, raffle, news){
  const url = `${API_URL}/enterEmail?uid=${uid}&email=${email}&raffle=${raffle}&news=${news}`
  return axios({
        method: 'post',
        url: url
    })
    .then(response => {
      return response.data
    }).catch(function (error) {
      return error;
    })
}
  

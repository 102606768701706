export const actionTypes = {
    SET_EXPERIMENT_TYPE: "SET_EXPERIMENT_TYPE",
    SET_EMAIL: "SET_EMAIL",
    SET_CONTENT: "SET_CONTENT",
    SET_LANGUAGE: "SET_LANGUAGE"
  };
  
  export const action_set_experiment_type = (expType) => {
    return {
      type: actionTypes.SET_EXPERIMENT_TYPE,
      payload: {expType},
    }
  };

  export const action_set_email = (email) => {
    return {
      type: actionTypes.SET_EMAIL,
      payload: {email},
    }
  };

  export const action_set_language = (lang) => {
    return {
      type: actionTypes.SET_LANGUAGE,
      payload: {lang},
    }
  }
  
  export const action_set_content = (chosenContent) => {
    return {
      type: actionTypes.SET_CONTENT,
      payload: {chosenContent},
    }
  };
  

import React from 'react';
import { withStyles, Button} from '@material-ui/core';
import {connect} from "react-redux";
import teaser from '../../static/images/teaser.jpg';

import {landing} from "../../static/text";
import {action_set_content} from "../../State/actions";
import { ContentEnum} from '../../util/enums';




class LandingComp extends React.Component {

    startExperiment(){
        //console.log("start");
        this.props.dispatch(action_set_content(Object.assign(ContentEnum.CompareExpTut, {})));
    }

    
    render() {
        return (
            <div className={this.props.classes.root}>
                
                <div className={this.props.classes.upperDiv}>
                    <div className={this.props.classes.upperTextDiv}>
                        <span className={this.props.classes.header}>
                            {landing.headline[this.props.language]}
                        </span>
                        <span className={this.props.classes.subHeadline}>
                            {landing.subHeadline[this.props.language]}
                        </span>
                    </div>
                    <div className={this.props.classes.teaserDiv}> 
                        <img src={teaser} className={this.props.classes.teaserImg}  alt=""></img>
                    </div>
                </div>
                <div className={this.props.classes.lowerTextDiv}>
                    <span className={this.props.classes.text}>
                        {landing.firstText[this.props.language]}
                    </span>
                    <span className={this.props.classes.text}>
                        {landing.secondText[this.props.language]}
                    </span>
{/*                     <span className={this.props.classes.text}>
                        {landing.thirdText[this.props.language]}
                    </span> */}
                </div>
                <div className={this.props.classes.buttonDiv}>
                    <Button onClick={this.startExperiment.bind(this)} className={this.props.classes.startButton}>
                        {landing.button[this.props.language]}
                    </Button>
                </div>
                <div className={this.props.classes.impressum}>
                    <a target="_blank" className={this.props.classes.link} href="https://visual-computing.com/impressum/">{landing.impressum[this.props.language]}</a>
                    <a target="_blank" className={this.props.classes.link} href="https://www.htw-berlin.de/datenschutz/">{landing.privacy[this.props.language]}</a>
                </div>
            </div>
        )
    }
} 

const styles = {
    root :{
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingLeft: "7%",
        paddingRight: "7%",
        paddingTop: "3%",
        whiteSpace: "pre-wrap",
    },
    link: {
        margin: "auto",
        marginRight: 0,
        fontSize: "1.3vmin",
    },
    impressum:{
        width: "20%",
        display: "flex",
        position: "absolute",
        top: "95%",
        right: "7%"
    },
    upperDiv :{
       display: "flex",
       height: "30%",
       marginTop: "2%",
       marginBottom: "5%"
    },

    upperTextDiv :{
        display: "flex",
        width: "85%",
        paddingRight: "5%",
        flexDirection: "column",
        fontSize: "2.3vmin",
        lineHeight: "2.3vmin",
        
    },
    
    subHeadline : {   // restlicher Text im Header
        height: "80%",
        fontSize: "4vmin",
        lineHeight: "4vmin",
        marginLeft: 0,
        fontWeight: "bold",
        display: "flex",
        alignItems: "end"
    },
    teaserDiv :{
        width: "15%",
    },
    teaserImg: {
        height: "100%",
        float: "right",
    },

    lowerTextDiv :{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        //height: "50%", 
     },

     buttonDiv :{ // start button
        display: "flex",
        width: "100%",
        height: "10%",
     },
     text : {
        fontSize: "2.5vmin",
        lineHeight: "3.5vmin",
        margin: "auto",
        marginBottom: "4vmin",
        marginTop: "0%",
        marginLeft: 0,
        paddingBottom: "0%",
        textAlign: "justify"
    },
    startButton : {
        fontSize: "2.5vmin",
        margin: "auto",
        marginTop: "5vmin",
        width: "45%",
    },
    header : { //  Prof. Dr. Kai Barthel 
        height: "20%",
        marginLeft: 0,
        fontWeight: "bold",
        marginBottom: "3%",
        margin: "auto",
    },
};

function mapStateToProps(state){
    return {
      language: state.language,

    }
  }

export default connect(mapStateToProps)(withStyles(styles)(LandingComp));